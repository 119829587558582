import React, { useState } from "react"

import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api'
import { TextField as MuiTextField } from '@mui/material'
import { validationError } from '@data-driven-forms/mui-component-mapper/validation-error/validation-error';

import Box from '@mui/material/Box';
import Icon from 'components/post/icon'

// TODO: Make a component
const TextFieldURL = (props) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    inputProps,
    FormFieldGridProps,
    ...rest
  } = useFieldApi(props);
  const invalid = validationError(meta, validateOnMount)


  const [value, setValue] = useState(input.value ? input.value.value : '')
  const [type, setType] = useState(input?.value?.label
    ? input.value.label
    : 'hyperlink')

  const checkType = (value) => {
    let newType = `hyperlink`
    if (value.includes(`twitter`)) newType = 'twitter'
    console.log(`This icon type `, value, newType)

    input.onChange(value)
    setValue(value)
    setType(newType)
    return newType
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end', flexGrow: '1' }}>
      <MuiTextField
        {...input.value}
        fullWidth
        error={!!invalid}
        helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
        disabled={isDisabled}
        label={input.label}
        onChange={(input) => checkType(input.target.value)}
        placeholder={placeholder}
        required={isRequired}
        style={{ marginLeft: '0px' }}
        inputProps={{
          readOnly: isReadOnly,
          ...inputProps,
        }}
        {...rest}
      />
      <Icon round={false} type={type} />
    </Box>
  )
}

export default TextFieldURL